import React from 'react';

const Default: React.FC<{ }> = ({  }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <h1 className="text-4xl">What are you looking for? 🤨 </h1>
        </div>
    );
};

export default Default;