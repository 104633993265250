import React from 'react';

interface TechArrayProps {
    tech: string;
}

const TechArray: React.FC<TechArrayProps> = ({ tech }) => {
    let techArray = tech?.split(', ');

    return (
        <div>
            {
                techArray?.map((item, index) => (
                    <span key={index} className='px-2 py-1 mx-1 font-medium bg-orange-100 rounded-2xl text-orange-400'>{item}</span>
                ))
            }
        </div>
    );
};

export default TechArray;