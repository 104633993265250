import ExperienceCard from "../components/experienceCard";
import LinkButton from "../components/linkButton";
import ProjectCard from "../components/projectCard";
import EducationCard from "../components/educationCard";
function Home() {
  return (
    <div className="w-full min-h-screen flex justify-center flex-col leading-relaxed  bg-zinc-100">
      <div className="fixed w-full h-44 b top-0 bg-gradient-to-b from-zinc-300 to-zinc-100 z-10">
        <span></span>
      </div>
      <div className="z-20">
        <div className="container mx-auto px-6 py-12 font-sans md:px-12 lg:py-0 ">
          <div className="container lg:flex lg:justify-between justify-center lg:gap-4">
            <header className="container lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:py-24">
              <h2 className="font-semibold text-xl text-gray-500 mb-2">
                Hi! I'm
              </h2>
              <div className="font-bold text-5xl bg-clip-text mb-2">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-orange-500 mb-2">
                  Martí López Pérez
                </span>
              </div>
              <h2 className="font-semibold text-xl text-gray-500 mb-2">
                Computer Engineering Student
              </h2>
              <p className="font-semibold text-gray-500 mb-4">
                Forever learning and coding.
              </p>
              <p className="text-gray-700 text-m">📍 - Barcelona</p>
              <p className="text-gray-700 text-m">
                ✉️ - marti.lopez.p@gmail.com
              </p>

              <div className="container lg:absolute lg:bottom-24 flex flex-row gap-4 my-4">
                <LinkButton
                  icon="icons8-github.svg"
                  link="https://github.com/martilopez"
                />

                <LinkButton
                  icon="icons8-linkedin-50.svg"
                  link="https://www.linkedin.com/in/martilopez"
                />
              </div>
            </header>
            <div className="container relative lg:w-1/2 ">
              <div
                id="about"
                className="container bg-zinc-50 border w-fit p-4 rounded-2xl shadow-xl text-gray-700 text-m  text-justify mb-12 lg:mt-24"
              >
                <p className="mb-4">
                  Ever since I was little, I loved technology and everything
                  related to computers, so studying computer engineering was a
                  no-brainer. Learning how to create software from zero has been
                  fun and challenging, but now I want to take it a step further
                  and apply the knowledge gained in the real world!
                </p>
                <p className="mb-4">
                  My main objective for the following years is to continue
                  learning everything there is to know about{" "}
                  <b className="duration-300 hover:bg-clip-text hover:text-transparent hover:bg-gradient-to-r hover:from-red-500 hover:to-orange-500">
                    fullstack development
                  </b>
                  , although if I had to choose between frontend and backend, I
                  will always choose frontend. 🙃
                </p>
                <p>
                  When I'm not in front of a screen, I'm usually exercising,
                  reading fantasy books or hanging out with my friends.
                </p>
              </div>
              <div id="experience" className="mb-12">
                <div className="font-semibold text-gray-500 mb-4 ">
                  Experience:
                </div>

                <ExperienceCard
                  position="Internship"
                  org="NTT DATA"
                  period="JUL 2023 - MAY 2024"
                  description="Developed process automation with .NET and Python. Created pipelines using Jenkins. Organized and prioritized parallel tasks."
                  tech="Python, .NET, Jenkins"
                />
              </div>
              <div id="education" className="mb-12">
                <div className="font-semibold text-gray-500 mb-4 ">
                  Education:
                </div>

                <EducationCard
                  position="Computer Engineering"
                  org="Universitat Autònoma de Barcelona"
                  period="SEP 2019 - JUN 2024"

                />
              </div>
              <div id="projects" className="">
                <div className="font-semibold text-gray-500 mb-4 ">
                  Projects:
                </div>
                <ProjectCard
                  title="Guaki - Bachelor's Degree Final Project"
                  body="An iOS and Android app that promotes healthy eating and has features like: barcode scanner, pantry and recipe planner"
                  tech="Expo, React Native, MongoDB"
                  img="guaki.png"
                />
              </div>
              <div className="mt-24  w-full h-px rounded-xl bg-zinc-300" />
              <p className="mt-3 font-light text-zinc-500 lg:mb-24 mb-12">
                This portfolio was created using React, Tailwind and deployed
                using AWS Amplify
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

