import React from 'react';

interface LinkButtonProps {
    icon: string;
    link: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({ icon, link }) => {
    return (
        <a href={link} target="_blank" rel="noreferrer" className="hover:scale-125 duration-100">
            <img src={icon} alt="icon" className="lg:w-10 lg:h-10 w-8 h-8" />
        </a>
    );
};

export default LinkButton;