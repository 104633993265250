import GuakiCarousell from "../components/guakiCarousell";

function Guaki() {
  return (
    <div className="w-full min-h-screen flex justify-center flex-col leading-relaxed  bg-zinc-100">
      <div className="z-20">
        <div className="container mx-auto min-h-screen max-w-screen-xl px-6 pb-12 font-sans md:px-12 lg:py-0 text-center ">
          <header className="container z-30 lg:sticky lg:top-0 lg:flex lg:w-full lg:flex-col bg-zinc-100 shadow-b-l lg:mb-12">
            <div
              className="group absolute cursor-pointer lg:ml-24 ml-6 mt-9 hover:-translate-x-3 duration-100"
              onClick={() => (window.location.href = "/")}
            >
              <svg
                className="w-12 h-12 group-hover:fill-green-500 group-active:fill-green-700 "
                height="512px"
                id="Layer_1"
                version="1.1"
                viewBox="0 0 512 512"
                width="512px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
              </svg>
            </div>
            <div className="font-bold text-7xl bg-clip-text mb-4 pt-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-lime-500 mb-2">
                Guaki
              </span>
            </div>
            <p className="text-zinc-700 text-lg font-medium lg:px-44 pb-6">
              An iOS and Android app created with React Native and Expo that promotes healthy eating for those who want to make cooking easier.
            </p>

            <div className="w-full h-px bg-gradient-to-b from-green-500 to-lime-500 rounded-2xl shadow-xl"></div>
          </header>

          <div className="w-full mx-auto lg:flex lg:flex-row gap-12">
            <div className="flex-1 flex flex-row place-content-center place-items-center">
              <img
                className={`w-48 md:w-72  transition-transform duration-500 hover:scale-110`}
                src="/guaki/PantryAdd1.png"
                alt={`Guaki image 1`}
              />
              <img
                className={`w-48 md:w-72 transition-transform duration-500 hover:scale-110`}
                src="/guaki/PantryFull.png"
                alt={`Guaki image 2`}
              />
            </div>
            <div className="flex-1 place-content-center place">
                <h2 className="text-2xl font-semibold text-gray-500 ">
                  Pantry
                </h2>

              
              <p className="text-zinc-700 text-m font-medium ">
                Manage your pantry through a organized food type interface to make you daily menus more simple.
              </p>
            </div>
          </div>
          <div className="w-full mx-auto lg:flex lg:flex-row-reverse gap-12">
            <div className="flex-1 flex flex-row place-content-center place-items-center">
              <img
                className={`w-48 md:w-72  transition-transform duration-500 hover:scale-110`}
                src="/guaki/Recipes1.png"
                alt={`Guaki image 1`}
              />
              <img
                className={`w-48 md:w-72 transition-transform duration-500 hover:scale-110`}
                src="/guaki/RecipesDetail.png"
                alt={`Guaki image 2`}
              />
            </div>
            <div className="flex-1 place-content-center ">
              <h2 className="text-2xl font-semibold text-gray-500 mb-4">
                Recipes
              </h2>
              <p className="text-zinc-700 text-m font-medium ">
                Meal recomendations based on what you have in your pantry and your preferences.
              </p>
            </div>
          </div>
          <div className="w-full mx-auto lg:flex lg:flex-row gap-12">
            <div className="flex-1 flex flex-row place-content-center place-items-center">
              <img
                className={`w-48 md:w-72  transition-transform duration-500 hover:scale-110`}
                src="/guaki/PlannerSearch.png"
                alt={`Guaki image 1`}
              />
              <img
                className={`w-48 md:w-72 transition-transform duration-500 hover:scale-110`}
                src="/guaki/PlannerFull.png"
                alt={`Guaki image 2`}
              />
            </div>
            <div className="flex-1 place-content-center ">
              <h2 className="text-2xl font-semibold text-gray-500 mb-4">
                Planner
              </h2>
              <p className="text-zinc-700 text-m font-medium ">
                Organize your weakly meals and with your favourite recipes and smart recomendations
              </p>
            </div>
          </div>
          <div className="w-full mx-auto lg:flex lg:flex-row-reverse gap-12">
            <div className="flex-1 flex flex-row place-content-center place-items-center">
              <img
                className={`w-48 md:w-72  transition-transform duration-500 hover:scale-110`}
                src="/guaki/Scanner.png"
                alt={`Guaki image 1`}
              />
              <img
                className={`w-48 md:w-72 transition-transform duration-500 hover:scale-110`}
                src="/guaki/ScannerInfo.png"
                alt={`Guaki image 2`}
              />
            </div>
            <div className="flex-1 place-content-center ">
              <h2 className="text-2xl font-semibold text-gray-500 mb-4">
                Scanner
              </h2>
              <p className="text-zinc-700 text-m font-medium ">
                Get nutritional information about the products you consume to make more informed decisions in the supermarket.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guaki;
