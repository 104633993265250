import React from 'react';
import TechArray from './techArray';

interface ProjectCardProps {
    title: string;
    body: string;
    tech?: string;
    img?: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ title, body, tech, img }) => {
    return (
        <div className="group cursor-pointer backdrop-blur-sm hover:bg-zinc-200 duration-75 hover:shadow-lg rounded-xl p-4" onClick={() => window.location.href = '/guaki'}>
            <h2 className='group-hover:text-red-500 font-semibold text-gray-700 mb-1'>
                {title}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="group-hover:fill-red-500 group-hover:-translate-y-1 group-hover:translate-x-1 inline-block h-4 w-4 shrink-0 transition-transform group-hover/link:-translate-y-1 group-hover/link:translate-x-1 group-focus-visible/link:-translate-y-1 group-focus-visible/link:translate-x-1 motion-reduce:transition-none ml-1 translate-y-px" aria-hidden="true"><path fill-rule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clip-rule="evenodd"></path></svg>

            </h2>
            <p className='text-gray-70 font-normal mb-2'>{body}</p>
            {
                tech && <TechArray tech={tech}/>
            }
                    
        </div>
    );
};

export default ProjectCard;