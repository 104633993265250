import React from 'react';
import TechArray from './techArray';

interface ExperienceCardProps {
    position: string;
    org: string;
    period: string;
    description: string;
    tech?: string;
}

const ExperienceCard: React.FC<ExperienceCardProps> = ({
    position,
    org,
    period,
    description,
    tech
}) => {

    return (
        <div className="container flex flex-row w-full mb-8">
            <div className='flex w-fit flex-row'>
                <div className="w-4 h-full bg-gradient-to-b from-red-500 to-orange-500 rounded-l-2xl ml-2 shadow-xl"></div>
                <div className="text-gray-700 bg-zinc-50 rounded-r-2xl shadow-xl px-4 py-2">
                    <div className='flex flex-row justify-between'>
                        <div className="font-semibold text-xl bg-clip-text ">
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-orange-500 mb-2">
                            {position}
                            </span>
                        </div>  
                        <p className="w-fit text-gray-700 text-sm pt-2 text-nowrap">{period}</p>
                    </div>
                    <p className='font-semibold text-gray-700 mb-1'>{org}</p>
                    <p className='text-gray-70 font-normal mb-2'>{description}</p>
                    {
                        tech && <TechArray tech={tech}/>
                    }
                    
                </div>
                
            </div>
        </div>
    );
};

export default ExperienceCard;