import React from 'react';
import TechArray from './techArray';

interface EducationCardProps {
    position: string;
    org: string;
    period: string;

}

const EducationCard: React.FC<EducationCardProps> = ({
    position,
    org,
    period,

}) => {

    return (
        <div className="container flex flex-row mb-8">
            <div className='flex w-full flex-row '>
                <div className="text-gray-700 bg-zinc-50 rounded-l-2xl shadow-xl px-4 py-2 w-full">
                    <div className='flex flex-row w-full justify-between '>
                        <div className="font-semibold text-xl bg-clip-text ">
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-orange-500 mb-2">
                            {position}
                            </span>
                        </div>  
                        <p className="w-fit text-gray-700 text-sm pt-2 text-nowrap">{period}</p>
                    </div>
                    <p className='font-semibold text-gray-700 mb-1'>{org}</p>

                    
                </div>
                <div className="w-4 h-full bg-gradient-to-b from-red-500 to-orange-500 rounded-r-2xl mr-2 shadow-xl"></div>

            </div>
        </div>
    );
};

export default EducationCard;